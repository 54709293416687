import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Addresses } from "./model-types";

export type GetAddressesParams = {
  acctIndexes: number[];
};

export type GetAddressesData = Addresses;

export async function getAddresses(
  params: GetAddressesParams & Pick<Options, "signal">,
): Promise<GetAddressesData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getJson<GetAddressesData>(
    `${GATEWAY_PATHS.externalPwmAddressService}/Address${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetAddressesParams) {
  // Destructure params
  const { acctIndexes } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({
    acctIndexes: acctIndexes.join(","),
  });

  // Create searchParams string
  const searchParams = `?${urlSearchParams.toString()}`;

  return searchParams;
}
