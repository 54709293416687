import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type TaxDocuments } from "./model-types";

export type GetTaxDocumentsParams = {
  acctIndex?: number[] | undefined;
  accountName?: string;
  taxYear?: number;
  taxDocType?: number;
};

export type GetTaxDocumentsData = TaxDocuments;

export async function getTaxDocuments(
  params: GetTaxDocumentsParams & Pick<Options, "signal">,
): Promise<GetTaxDocumentsData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetTaxDocumentsData>(
    `${GATEWAY_PATHS.externalTaxDocumentsService}/TaxDocuments${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetTaxDocumentsParams) {
  // Destructure params
  const { acctIndex, accountName, taxYear, taxDocType } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (acctIndex) {
    urlSearchParams.set("acctIndex", acctIndex.join(","));
  }
  if (accountName) {
    urlSearchParams.set("accountName", accountName);
  }
  if (taxYear) {
    urlSearchParams.set("taxYear", `${taxYear}`);
  }
  if (taxDocType) {
    urlSearchParams.set("taxDocType", `${taxDocType}`);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
