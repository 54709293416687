import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Subscribed } from "./model-types";

export type GetSharedSubscribedData = Subscribed;

export async function getSharedSubscribed(
  params: Pick<Options, "signal">,
): Promise<GetSharedSubscribedData> {
  // Call endpoint
  const data = await getJson<GetSharedSubscribedData>(
    `${GATEWAY_PATHS.externalBolAccountService}/Shared/Subscribed`,
    { signal: params.signal },
  );

  // Success
  return data;
}
