import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type PublishedAccount } from "./model-types";

export type GetSharedSubscribersByAccountIndexParams = {
  accountIndex: number;
};

export type GetSharedSubscribersByAccountIndexData = PublishedAccount;

export async function getSharedSubscribersByAccountIndex(
  params: GetSharedSubscribersByAccountIndexParams & Pick<Options, "signal">,
): Promise<GetSharedSubscribersByAccountIndexData> {
  // Parse incoming
  const { accountIndex } = parseParams(params);

  // Call endpoint
  const data = await getJson<GetSharedSubscribersByAccountIndexData>(
    `${GATEWAY_PATHS.externalBolAccountService}/Shared/Subscribers/${accountIndex}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetSharedSubscribersByAccountIndexParams) {
  const accountIndex = encodeURIComponent(params.accountIndex);
  return { accountIndex };
}
