import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Watchlists } from "./model-types";

export type GetWatchlistNamesData = Watchlists;

export async function getWatchlistNames(
  params: Pick<Options, "signal">,
): Promise<GetWatchlistNamesData> {
  // Call endpoint
  const data = await getJson<GetWatchlistNamesData>(
    `${GATEWAY_PATHS.pwmPortfolioService}/Watchlist`,
    { signal: params.signal },
  );

  // Success
  return data;
}
