import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type AssetAllocation } from "./model-types";

export type GetAssetAllocationsParams = {
  categorizationType: "AssetClass" | "AssetCategory";
  acctIndex?: [number, ...number[]] | undefined;
  groupId?: string | undefined;
};

export type GetAssetAllocationsData = AssetAllocation;

export async function getAssetAllocations(
  params: GetAssetAllocationsParams & Pick<Options, "signal">,
): Promise<GetAssetAllocationsData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getJson<GetAssetAllocationsData>(
    `${GATEWAY_PATHS.pwmPortfolioService}/AssetAllocations${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetAssetAllocationsParams) {
  // Destructure params
  const { categorizationType, acctIndex, groupId } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ categorizationType });
  if (acctIndex) {
    urlSearchParams.set("acctIndex", acctIndex.join(","));
  }
  if (groupId && groupId.length > 0) {
    urlSearchParams.set("groupId", groupId);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
