import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type MetaData } from "./model-types";

export type GetMetaDataData = MetaData;

export async function getMetaData(
  params: Pick<Options, "signal">,
): Promise<GetMetaDataData> {
  // Call endpoint
  const data = await getJson<GetMetaDataData>(
    `${GATEWAY_PATHS.externalUserProfileService}/MetaData`,
    { signal: params.signal },
  );

  // Success
  return data;
}
