import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetStatementsLatestAvailableDateData = string;

export async function getStatementsLatestAvailableDate(
  params: Pick<Options, "signal">,
): Promise<GetStatementsLatestAvailableDateData> {
  // Call endpoint
  const data = await getJson<GetStatementsLatestAvailableDateData>(
    `${GATEWAY_PATHS.externalStatementsService}/Statements/LatestAvailableDate`,
    { signal: params.signal },
  );

  // Success
  return data;
}
