import { getBlob, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetClientCommunicationsPdfParams = {
  key: string;
};

export type GetClientCommunicationsPdfData = Blob;

export async function getClientCommunicationsPdf(
  params: GetClientCommunicationsPdfParams & Pick<Options, "signal">,
): Promise<GetClientCommunicationsPdfData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getBlob(
    `${GATEWAY_PATHS.externalClientsCommunicationsService}/ClientCommunications/pdf${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetClientCommunicationsPdfParams) {
  // Destructure params
  const { key } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ key });

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
