/*
TODO: This module contains anti-patterns and should be refactored then deleted.
*/
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { queryOptions } from "@tanstack/react-query";
import { allQueryKeys } from "../allQueryKeys";
import { type GetUserEligibilityData } from "./get-user-eligibility";
import { type EligibilityResponse } from "./model-types";

export function useTrustEligibilityMenuQueryOptions() {
  const jwt = useJwt();
  const isEnabled =
    jwt.requiredActivities.length === 0 &&
    [Segment.IndividualInvestors, Segment.PrivateAssetManagement, Segment.Preclient].includes(
      jwt.segment,
    );
  const defaultData = { trust: "Hide" } as const satisfies EligibilityResponse;

  return queryOptions({
    queryKey: allQueryKeys.externalTrustService.userEligibility.queryKey,
    queryFn: (context) =>
      isEnabled
        ? (
            allQueryKeys.externalTrustService.userEligibility.queryFn(
              context,
            ) as Promise<GetUserEligibilityData>
          ).catch(() => defaultData)
        : defaultData,
    staleTime: isEnabled ? Infinity : 0,
    gcTime: isEnabled ? 0 : undefined,
    throwOnError: false,
  });
}
