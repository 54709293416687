import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetPortalSsoData = string;

export async function getPortalSso(
  params: Pick<Options, "signal">,
): Promise<GetPortalSsoData> {
  // Call endpoint
  const data = await getJson<GetPortalSsoData>(
    `${GATEWAY_PATHS.externalTrustService}/Portal/SSO`,
    { signal: params.signal },
  );

  // Success
  return data;
}
