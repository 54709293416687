import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type RecentActivity } from "./model-types";

export type GetRecentActivityParams = {
  /** Format: yyyy-MM-dd */
  startDate?: string | undefined;
  acctIndex?: [number, ...number[]] | undefined;
  categoryFilter?:
    | "None"
    | "MoneyMarket"
    | "Money"
    | "Security"
    | "Trade"
    | "IncomeAndExpense"
    | "VIPChecking"
    | "VIPCardActivity"
    | "TransfersIn"
    | "CheckDeposits"
    | undefined;
  activitySortValue?:
    | "None"
    | "Date"
    | "Activity"
    | "AssetClassification"
    | "Quantity"
    | "Description"
    | "Type"
    | "Symbol"
    | "Price"
    | "Amount"
    | undefined;
  sortDirection?: "Descending" | "Ascending" | undefined;
};

export type GetRecentActivityData = RecentActivity;

export async function getRecentActivity(
  params: GetRecentActivityParams & Pick<Options, "signal">,
): Promise<GetRecentActivityData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetRecentActivityData>(
    `${GATEWAY_PATHS.pwmPortfolioService}/RecentActivity${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetRecentActivityParams) {
  // Destructure params
  const {
    startDate,
    acctIndex,
    categoryFilter,
    activitySortValue,
    sortDirection,
  } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (startDate) {
    urlSearchParams.set("startDate", startDate);
  }
  if (acctIndex) {
    urlSearchParams.set("acctIndex", acctIndex.join(","));
  }
  if (categoryFilter) {
    urlSearchParams.set("categoryFilter", categoryFilter);
  }
  if (activitySortValue) {
    urlSearchParams.set("activitySortValue", activitySortValue);
  }
  if (sortDirection) {
    urlSearchParams.set("sortDirection", sortDirection);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
