import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type MandatoryActivity } from "./model-types";

export type GetMandatoryActivityData = MandatoryActivity;

export async function getMandatoryActivity(
  params: Pick<Options, "signal">,
): Promise<GetMandatoryActivityData> {
  // Call endpoint
  const data = await getJson<GetMandatoryActivityData>(
    `${GATEWAY_PATHS.externalBolNotificationService}/MandatoryActivity`,
    { signal: params.signal },
  );

  // Success
  return data;
}
