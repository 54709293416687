import { getJwt } from "#app/(unauthorized)/authentication/jwtStorageService";
import { getText, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetSamlData = string;

export async function getSaml(
  params: Pick<Options, "signal">,
): Promise<GetSamlData> {
  // Call endpoint
  const data = await getText(`${GATEWAY_PATHS.bolAuthenticationService}/Saml`, {
    headers: { JWT: getJwt() || "" },
    signal: params.signal,
  });

  // Success
  return data;
}
