import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Notification } from "./model-types";

export type GetNotificationParams = {
  failOnServiceOutage?: boolean;
};

export type GetNotificationData = Notification;

export async function getNotification(
  params?: GetNotificationParams & Pick<Options, "signal">,
): Promise<GetNotificationData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetNotificationData>(
    `${GATEWAY_PATHS.externalBolNotificationService}/Notification${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetNotificationParams) {
  // Destructure params
  const { failOnServiceOutage } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (failOnServiceOutage !== undefined) {
    urlSearchParams.set("failOnServiceOutage", String(failOnServiceOutage));
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
