import {
  type Nickname,
  useAccountNickNameQueryOptions,
} from "#app/_api/user-profile-service";
import { useQuery } from "@tanstack/react-query";

export type NicknameLookup = {
  [Account: string]: string;
};

const reduceNicknames = (prev: NicknameLookup, cur: Nickname) => {
  return { ...prev, [cur.accountNumber]: cur.name ?? "" };
};

export const useAccountNicknameLookup = () => {
  const { data = [] } = useQuery(useAccountNickNameQueryOptions());
  return data.reduce(reduceNicknames, {});
};
