import { getBlob, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetStatementsPdfParams = {
  documentId?: number;
  includeBacker?: boolean;
};

export type GetStatementsPdfData = Blob;

export async function getStatementsPdf(
  params: GetStatementsPdfParams & Pick<Options, "signal">,
): Promise<GetStatementsPdfData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getBlob(
    `${GATEWAY_PATHS.externalStatementsService}/Statements/pdf${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetStatementsPdfParams) {
  // Destructure params
  const { documentId, includeBacker } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (documentId) {
    urlSearchParams.set("documentId", `${documentId}`);
  }
  if (includeBacker) {
    urlSearchParams.set("includeBacker", `${includeBacker}`);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
