import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type SharingInvites } from "./model-types";

export type GetSharingInvitesData = SharingInvites;

export async function getSharingInvites(
  params: Pick<Options, "signal">,
): Promise<GetSharingInvitesData> {
  // Call endpoint
  const data = await getJson<GetSharingInvitesData>(
    `${GATEWAY_PATHS.externalBolAccountService}/Sharing/Invites`,
    { signal: params.signal },
  );

  // Success
  return data;
}
