import { type Options } from "#app/lib/fetchClient";
import { getAccountGroups } from "../external-user-profile-service/get-account-groups";
import { type AccountGroups } from "../external-user-profile-service/model-types";
import { getPortfolio } from "./get-portfolio";
import { type Portfolio } from "./model-types";

export type GetAccountGroupsPortfoliosData = {
  accountGroups: AccountGroups;
  portfolios: Array<Portfolio>;
};

export async function getAccountGroupsPortfolios(
  params: Pick<Options, "signal">,
): Promise<GetAccountGroupsPortfoliosData> {
  // Call first endpoint
  const accountGroups = await getAccountGroups({ signal: params.signal });

  // Call subsequent dependent endpoints
  const portfolios = await Promise.all(
    accountGroups
      .filter((el) => el.acctIndexes.length > 0)
      .map((el) => getPortfolio({ groupId: el.id, signal: params.signal })),
  );

  // Success
  return { accountGroups, portfolios };
}
