import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Settings } from "./model-types";

export type GetSettingsParams = {
  accountIndexes?: number[];
};

export type GetSettingsData = Settings;

export async function getSettings(
  params: GetSettingsParams & Pick<Options, "signal">,
): Promise<GetSettingsData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetSettingsData>(
    `${GATEWAY_PATHS.externalEDeliveryService}/Settings${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetSettingsParams) {
  // Destructure params
  const { accountIndexes } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (accountIndexes) {
    urlSearchParams.set("accountIndexes", accountIndexes.join(","));
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}

// -----------------------------------------------------------------------------
// Transform utils
// -----------------------------------------------------------------------------

export type PaperlessVm = {
  accountNumber: GetSettingsData[number]["accountNumber"];
  statements: GetSettingsData[number]["settings"][number]["method"] | null;
  confirms: GetSettingsData[number]["settings"][number]["method"] | null;
  clientCom: GetSettingsData[number]["settings"][number]["method"] | null;
  tax: GetSettingsData[number]["settings"][number]["method"] | null;
  fdr: GetSettingsData[number]["settings"][number]["method"] | null;
  hasFdr: boolean;
  hasErrors: boolean;
  access: GetSettingsData[number]["access"];
};

export function transformSettings(value: GetSettingsData): Array<PaperlessVm> {
  return value.map((val) => ({
    accountNumber: val.accountNumber,
    statements:
      val.settings.filter((el) => el.type === "Statement")[0]?.method ?? null,
    confirms:
      val.settings.filter((el) => el.type === "Confirmation")[0]?.method ??
      null,
    clientCom:
      val.settings.filter(
        (el) => el.type === "ElectronicInvestorCommunication",
      )[0]?.method ?? null,
    tax:
      val.settings.filter((el) => el.type === "TaxDocEdeliveryPreference")[0]
        ?.method ?? null,
    fdr:
      val.settings.filter((el) => el.type === "FirmDrivenReporting")[0]
        ?.method ?? null,
    hasFdr:
      val.settings.filter((el) => el.type === "FirmDrivenReporting").length !==
      0,
    hasErrors:
      val.settingErrors.filter((el) => el.type != "FeatureNotEnabled").length >
      0,
    access: val.access,
  }));
}
