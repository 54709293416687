import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Portfolio } from "./model-types";

export type GetPortfolioParams = {
  includeDetails?: boolean | undefined;
  acctIndex?: [number, ...number[]] | undefined;
  groupId?: string | undefined;
};

export type GetPortfolioData = Portfolio;

export async function getPortfolio(
  params: GetPortfolioParams & Pick<Options, "signal">,
): Promise<GetPortfolioData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetPortfolioData>(
    `${GATEWAY_PATHS.pwmPortfolioService}/Portfolio${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetPortfolioParams) {
  // Destructure params
  const { includeDetails, acctIndex, groupId } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (includeDetails !== undefined) {
    urlSearchParams.set("includeDetails", String(includeDetails));
  }
  if (acctIndex) {
    urlSearchParams.set("acctIndex", acctIndex.join(","));
  }
  if (groupId && groupId.length > 0) {
    urlSearchParams.set("groupId", groupId);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
