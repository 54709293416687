import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type TaxDocumentTypes } from "./model-types";

export type GetTaxDocumentTypesData = TaxDocumentTypes;

export async function getTaxDocumentTypes(
  params: Pick<Options, "signal">,
): Promise<GetTaxDocumentTypesData> {
  // Call endpoint
  const data = await getJson<GetTaxDocumentTypesData>(
    `${GATEWAY_PATHS.externalTaxDocumentsService}/TaxDocumentTypes`,
    { signal: params.signal },
  );

  // Success
  return data;
}
