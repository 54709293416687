import { getText, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetSharingTermsAndConditionsViewerData = string;

export async function getSharingTermsAndConditionsViewer(
  params: Pick<Options, "signal">,
): Promise<GetSharingTermsAndConditionsViewerData> {
  // Call endpoint
  const data = await getText(
    `${GATEWAY_PATHS.externalBolAccountService}/Sharing/TermsAndConditions/Viewer`,
    { signal: params.signal },
  );

  // Success
  return data;
}
