import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Subscribers } from "./model-types";

export type GetSharedSubscribersData = Subscribers;

export async function getSharedSubscribers(
  params: Pick<Options, "signal">,
): Promise<GetSharedSubscribersData> {
  // Call endpoint
  const data = await getJson<GetSharedSubscribersData>(
    `${GATEWAY_PATHS.externalBolAccountService}/Shared/Subscribers`,
    { signal: params.signal },
  );

  // Success
  return data;
}
