import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type UserEligibility } from "./model-types";

export type GetUserEligibilityData = UserEligibility;

export async function getUserEligibility(
  params: Pick<Options, "signal">,
): Promise<GetUserEligibilityData> {
  // Call endpoint
  const data = await getJson<GetUserEligibilityData>(
    `${GATEWAY_PATHS.externalCashManagementService}/User/Eligibility`,
    { signal: params.signal },
  );

  // Success
  return data;
}
