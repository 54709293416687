import { getText, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetSharingTermsAndConditionsOwnerData = string;

export async function getSharingTermsAndConditionsOwner(
  params: Pick<Options, "signal">,
): Promise<GetSharingTermsAndConditionsOwnerData> {
  // Call endpoint
  const data = await getText(
    `${GATEWAY_PATHS.externalBolAccountService}/Sharing/TermsAndConditions/Owner`,
    { signal: params.signal },
  );

  // Success
  return data;
}
