import {
  getCurrentJwt,
  type Jwt,
} from "#app/(unauthorized)/authentication/jwt";
import { Segment } from "#app/(unauthorized)/authentication/segment";
import config from "#app/env";
import ky, { HTTPError, type KyResponse, type Options } from "ky";
import { trimStart } from "lodash";
import { TimeSpan } from "./TimeSpan";
import { logError } from "./logger";

export { type KyResponse, type Options };
export type OptionsPickJson = Options & { json: Required<Options["json"]> };
export type OptionsOmitJson = Omit<Options, "json"> & { json?: never };

const timeout: number = TimeSpan.fromSeconds(30).totalMilliseconds;

export const bolApi = ky.extend({
  prefixUrl: config.gatewayBaseUrl,
  timeout: timeout,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set("PdsClientVer", "2");
        request.headers.set("PdsClientType", "Web");
        if (!isAnonymousApi(new URL(request.url).pathname)) {
          const jwt = getCurrentJwt();
          if (jwt.segment === Segment.NotLoggedIn) {
            throw Error("User not logged in");
          }

          const authHeaders = getExternalGatewayHeaders(jwt);
          if (authHeaders) {
            for (const key in authHeaders) {
              request.headers.set(key, authHeaders[key]!);
            }
          }
        }
      },
    ],
    beforeError: [
      (error) => {
        if (error.response.status === 451) {
          logError(error);
          const jwt = getCurrentJwt();
          if (jwt.segment === Segment.NotLoggedIn) {
            window.location.href = "/sign-in?requestBlocked=U";
          } else {
            window.location.href = "/sign-out?requestBlocked=A";
          }
        }
        return new ApiError(error.response.status, error);
      },
    ],
  },
});

const anonymousApis = new Set([
  "authentication",
  "registration",
  "marketresearch",
]);

function getApiNameFromRoute(apiRoute: string): string {
  let splitRoute: string[] = [];
  if (apiRoute) {
    splitRoute = apiRoute.split("/");
  }

  if (splitRoute.length < 3 || !splitRoute[2]) {
    throw new Error("Invalid apiRoute");
  }

  return splitRoute[2];
}

function isAnonymousApi(url: string) {
  const apiName = getApiNameFromRoute(url);
  return anonymousApis.has(apiName);
}

function getExternalGatewayHeaders(
  jwt: Jwt,
): Record<string, string> | undefined {
  if (jwt.segment !== Segment.NotLoggedIn && jwt.jwtAsString) {
    return {
      Authorization: `Bearer ${jwt.oat}`,
      jwt: jwt.jwtAsString,
    };
  }

  return;
}

function trimLeadingSlash(value: string) {
  return trimStart(value, "/");
}

export interface ApiErrorResult {
  errors?: { [key: string]: string[] };
}

export class ApiError extends HTTPError {
  constructor(
    public code: number,
    httpError: HTTPError,
  ) {
    super(httpError.response, httpError.request, httpError.options);
  }
}

export function fetchBlob(
  apiUrl: string,
  options?: Omit<Options, "timeout" | "hooks">,
) {
  return ky
    .get(apiUrl, {
      ...options,
      timeout,
      hooks: {
        beforeError: [
          (error) => {
            return new ApiError(error.response.status, error);
          },
        ],
      },
    })
    .blob();
}

/* GET */

export async function getJson<T = void>(apiUrl: string, options?: Options) {
  return await bolApi.get(trimLeadingSlash(apiUrl), options).json<T>();
}

export async function getBlob(apiUrl: string, options?: Options) {
  return await bolApi.get(trimLeadingSlash(apiUrl), options).blob();
}

export async function getText(apiUrl: string, options?: Options) {
  return await bolApi.get(trimLeadingSlash(apiUrl), options).text();
}

/* POST */

export async function post(apiUrl: string, options: OptionsPickJson) {
  return await bolApi.post(trimLeadingSlash(apiUrl), options);
}

export async function postWithoutBody(
  apiUrl: string,
  options?: OptionsOmitJson,
) {
  return await bolApi.post(trimLeadingSlash(apiUrl), options);
}

export async function postJson<T = void>(
  apiUrl: string,
  options: OptionsPickJson,
) {
  return await bolApi.post(trimLeadingSlash(apiUrl), options).json<T>();
}

export async function postJsonWithoutBody<T = void>(
  apiUrl: string,
  options?: OptionsOmitJson,
) {
  return await bolApi.post(trimLeadingSlash(apiUrl), options).json<T>();
}

export async function postBlob(apiUrl: string, options: OptionsPickJson) {
  return await bolApi.post(trimLeadingSlash(apiUrl), options).blob();
}

/* PUT */

export async function putJson<T = void>(apiUrl: string, options?: Options) {
  return await bolApi.put(trimLeadingSlash(apiUrl), options).json<T>();
}

export async function putText(apiUrl: string, options: OptionsPickJson) {
  return await bolApi.put(trimLeadingSlash(apiUrl), options).text();
}

/* PATCH */

export async function patch(apiUrl: string, options: OptionsPickJson) {
  return await bolApi.patch(trimLeadingSlash(apiUrl), options);
}

export async function patchJson<T = void>(
  apiUrl: string,
  options: OptionsPickJson,
) {
  return await bolApi.patch(trimLeadingSlash(apiUrl), options).json<T>();
}

/* DELETE */

export async function remove(apiUrl: string, options?: Options) {
  return await bolApi.delete(trimLeadingSlash(apiUrl), options);
}

export async function removeJson<T = void>(apiUrl: string, options?: Options) {
  return await bolApi.delete(trimLeadingSlash(apiUrl), options).json<T>();
}

export async function removeText(apiUrl: string, options?: Options) {
  return await bolApi.delete(trimLeadingSlash(apiUrl), options).text();
}
