import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { type MfaResponse } from "#app/_api/authentication-service";
import { type MfaChallenge } from '#app/_ui/components/mfa/shared';
import { postWithoutBody } from '#app/lib/fetchClient';

type MfaResult = {
    mfa: MfaResponse
};

export enum MfaState {
    Mfaing,
    ViewPage,
    Errored
}

export async function mfaAnalyzeBase(gatewayUrlPart: string) : Promise<MfaChallenge> {
    const deviceInfo = window.urlEncode(window.add_deviceprint());
    const jwt = getCurrentJwt();

    const deviceCookie = localStorage.getItem('deviceCookie') || '';
    const result = await postWithoutBody
        (`/${gatewayUrlPart}/Mfa/Analyze?deviceInfo=${deviceInfo}&deviceTokenCookie=${encodeURIComponent(deviceCookie)}`);

    if (result.status == 204) {
        return {
            data:null,
            session:'',
            allowedChallengeTypes:[],
            deviceCookie: ''
        }
    }
    
    const data = await result.json<MfaResult>();

    return { 
        data: { loginId : jwt.subject },
        session: data?.mfa.mfaSessionValueEncrypted ?? '', 
        allowedChallengeTypes: data?.mfa.mfaAllowedChallengeTypes ?? [],
        deviceCookie: deviceCookie || ''
    };
}
