import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetEBillSsoData = string;

export async function getEBillSso(
  params: Pick<Options, "signal">,
): Promise<GetEBillSsoData> {
  // Call endpoint
  const data = await getJson<GetEBillSsoData>(
    `${GATEWAY_PATHS.externalCashManagementService}/EBill/SSO`,
    { signal: params.signal },
  );

  // Success
  return data;
}
