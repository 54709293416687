export const GATEWAY_PATHS = {
  bolAuthenticationService: "authentication/v1",
  externalBairdResearchService: "bairdresearch/v1",
  externalBolAccountService: "accounts/v1",
  externalBolNotificationService: "notifications/v1",
  externalBolRegistrationService: "registration/v1",
  externalCashManagementService: "CashManagement/v1",
  externalClientsCommunicationsService: "clientcommunications/v1",
  externalConfirmationsService: "Confirmations/v1",
  externalEDeliveryService: "eDelivery/v1",
  externalFinancialPlanService: "financialplans/v1",
  externalFundTransferService: "fundtransfer/v1",
  externalHeldawayService: "heldaway/v1",
  externalMarketingService: "marketing/v1",
  externalMarketResearchService: "marketresearch/v1",
  externalPerformanceReportingService: "performancereports/v1",
  externalPwmAddressService: "address/v1",
  externalSecureDocumentService: "ExternalSecureDocument/v1",
  externalStatementsService: "Statements/v1",
  externalTaxDocumentsService: "taxdocuments/v1",
  externalTrustService: "trust/v1",
  externalUserProfileService: "UserProfiles/v1",
  pwmPortfolioService: "pwmportfolios/v1",
} as const;
