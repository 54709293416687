import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Confirmations } from "./model-types";

export type GetConfirmationsParams = {
  accountNumberIndex?: number[] | undefined;
  symbol?: string | undefined;
  beginDate?: string | undefined;
  endDate?: string | undefined;
};

export type GetConfirmationsData = Confirmations;

export async function getConfirmations(
  params: GetConfirmationsParams & Pick<Options, "signal">,
): Promise<GetConfirmationsData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetConfirmationsData>(
    `${GATEWAY_PATHS.externalConfirmationsService}/Confirmations${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetConfirmationsParams) {
  // Destructure params
  const { accountNumberIndex, symbol, beginDate, endDate } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (accountNumberIndex) {
    urlSearchParams.set("accountNumberIndex", accountNumberIndex.join(","));
  }
  if (symbol) {
    urlSearchParams.set("symbol", symbol);
  }
  if (beginDate) {
    urlSearchParams.set("beginDate", beginDate);
  }
  if (endDate) {
    urlSearchParams.set("endDate", endDate);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
