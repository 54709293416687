import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Statements } from "./model-types";

export type GetStatementsParams = {
  accountNumberIndex?: number[] | undefined;
  month?: number | undefined;
  year?: number | undefined;
};

export type GetStatementsData = Statements;

export async function getStatements(
  params: GetStatementsParams & Pick<Options, "signal">,
): Promise<GetStatementsData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetStatementsData>(
    `${GATEWAY_PATHS.externalStatementsService}/Statements${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetStatementsParams) {
  // Destructure params
  const { accountNumberIndex, month, year } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (accountNumberIndex) {
    urlSearchParams.set("accountNumberIndex", accountNumberIndex.join(","));
  }
  if (month) {
    urlSearchParams.set("month", `${month}`);
  }
  if (year) {
    urlSearchParams.set("year", `${year}`);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
