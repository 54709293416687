import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Watchlist } from "./model-types";

export type GetWatchlistByNameParams = {
  name: string;
};

export type GetWatchlistByNameData = Watchlist;

export async function getWatchlistByName(
  params: GetWatchlistByNameParams & Pick<Options, "signal">,
): Promise<GetWatchlistByNameData> {
  // Parse incoming
  const { name } = parseParams(params);

  // Call endpoint
  const data = await getJson<GetWatchlistByNameData>(
    `${GATEWAY_PATHS.pwmPortfolioService}/Watchlist/${name}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetWatchlistByNameParams) {
  const name = encodeURIComponent(params.name);
  return { name };
}
