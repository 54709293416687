import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Documents } from "./model-types";

export type GetClientCommunicationsParams = {
  indexes: number[];
};

export type GetClientCommunicationsData = Documents;

export async function getClientCommunications(
  params: GetClientCommunicationsParams & Pick<Options, "signal">,
): Promise<GetClientCommunicationsData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getJson<GetClientCommunicationsData>(
    `${GATEWAY_PATHS.externalClientsCommunicationsService}/ClientCommunications${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetClientCommunicationsParams) {
  // Destructure params
  const { indexes } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ indexes: indexes.join(",") });

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
