import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetPreclientRegistrationVerifyInviteCodeParams = {
  inviteCode: string;
};

export type GetPreclientRegistrationVerifyInviteCodeData = boolean;

export async function getPreclientRegistrationVerifyInviteCode(
  params: GetPreclientRegistrationVerifyInviteCodeParams &
    Pick<Options, "signal">,
): Promise<GetPreclientRegistrationVerifyInviteCodeData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getJson<GetPreclientRegistrationVerifyInviteCodeData>(
    `${GATEWAY_PATHS.externalBolRegistrationService}/PreclientRegistration/VerifyInviteCode${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetPreclientRegistrationVerifyInviteCodeParams) {
  // Destructure params
  const { inviteCode } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ inviteCode });

  // Create searchParams string
  const searchParams = `?${urlSearchParams.toString()}`;

  return searchParams;
}
