import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type AccountGroups } from "./model-types";

export type GetAccountGroupsData = AccountGroups;

export async function getAccountGroups(
  params: Pick<Options, "signal">,
): Promise<GetAccountGroupsData> {
  // Call endpoint
  const data = await getJson<GetAccountGroupsData>(
    `${GATEWAY_PATHS.externalUserProfileService}/AccountGroups`,
    { signal: params.signal },
  );

  // Success
  return data;
}
