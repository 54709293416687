import { getBlob, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetTaxDocumentsSinglePdfParams = {
  docId: number;
  includeBacker?: boolean;
};

export type GetTaxDocumentsSinglePdfData = Blob;

export async function getTaxDocumentsSinglePdf(
  params: GetTaxDocumentsSinglePdfParams & Pick<Options, "signal">,
): Promise<GetTaxDocumentsSinglePdfData> {
  // Parse incoming
  const { docId, searchParams } = parseParams(params);

  // Call endpoint
  const data = await getBlob(
    `${GATEWAY_PATHS.externalTaxDocumentsService}/TaxDocuments/${docId}/Pdf${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetTaxDocumentsSinglePdfParams) {
  // Destructure params
  const { docId, includeBacker } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (includeBacker) {
    urlSearchParams.set("includeBacker", `${includeBacker}`);
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return { docId: encodeURIComponent(docId), searchParams };
}
