'use client';

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";


export function generateDocumentsQuery({accounts, beginDate, endDate} : DocumentsEndpointParams){
    const accQuery = generateDocumentsAccountsQuery({accounts,beginDate, endDate});
    const dateQuery = generateDocumentsDateQuery({accounts,beginDate, endDate});
    return `${accQuery}&${dateQuery}`;
}

export function generateDocumentsAccountsQuery({accounts} : DocumentsEndpointParams){
    const jwt = getCurrentJwt();
    const accountIndices = jwt.getAccountIndex(accounts);
    const indexes = accountIndices.map((a) => encodeURIComponent(a)).join(",");

    return `acctIndex=${indexes}`;
}

export function generateDocumentsDateQuery({beginDate, endDate} : DocumentsEndpointParams){
    return `BeginDate=${beginDate.toISOString()}&EndDate=${endDate.toISOString()}`;
}


export type DocumentsEndpointParams = {
    accounts: string[],
    beginDate: Date,
    endDate: Date,
}

export type DocumentMetaData = {
    id: string,
    parentId: string,
    date: Date,
    type: DocumentType,
    desc: string,
    docs: DocumentInfo[],
    accountNumber: string,
    action: ActionInfo,
    statement: StatementInfo,
    confirm: ConfirmInfo,
    communication: CommunicationInfo,
    tax : TaxInfo,
    secureDocExchange: SecureDocExchangeInfo,
    check: CheckInfo,
    finPlan: FinPlanInfo
};

export type DocumentInfo = {
    desc: string,
    url: string,
    isRelative: boolean
};

export type ActionInfo = {
	desc: string,
	url: string
};

export type StatementInfo =  {
	repCode: string,
	marketValue: number,
	parentAccount: number,
	isMasterConnect: boolean,
	statementRange: string,
    isCais: boolean,
    statementBackerUrl: string
};

export type ConfirmInfo = { };

export type CommunicationInfo =
{
	name: string,
	type: string,
	alertSymbol: string,
	alertCusip: string,
	alertActionType: string,
	alertActionDescription: string
};

export type TaxInfo = { };

export type SecureDocExchangeInfo = { };

export type CheckInfo = { };

export type FinPlanInfo = 
{
	name: string,
	branchId: string,
	firstName: string,
	lastName: string,
	userId: string,
	householdName: string,
	planType: string,
	datePlanCreated: Date,
	datePlanUpdated: Date
};