import { getBlob, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";

export type GetConfirmationsPdfParams = {
  documentId: number;
};

export type GetConfirmationsPdfData = Blob;

export async function getConfirmationsPdf(
  params: GetConfirmationsPdfParams & Pick<Options, "signal">,
): Promise<GetConfirmationsPdfData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getBlob(
    `${GATEWAY_PATHS.externalConfirmationsService}/Confirmations/pdf${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetConfirmationsPdfParams) {
  // Destructure params
  const { documentId } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ documentId: `${documentId}` });

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
