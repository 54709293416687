import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type PageStatuses } from "./model-types";

export type GetMessagePageData = PageStatuses;

export async function getMessagePage(
  params: Pick<Options, "signal">,
): Promise<GetMessagePageData> {
  // Call endpoint
  const data = await getJson<GetMessagePageData>(
    `${GATEWAY_PATHS.bolAuthenticationService}/Message/Page`,
    { signal: params.signal },
  );

  // Success
  return data;
}
