import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type OutageStatus } from "./model-types";

export type GetRegistrationOutageData = OutageStatus;

export async function getRegistrationOutage(
  params: Pick<Options, "signal">,
): Promise<GetRegistrationOutageData> {
  // Call endpoint
  const data = await getJson<GetRegistrationOutageData>(
    `${GATEWAY_PATHS.externalBolRegistrationService}/Registration/Outage`,
    { signal: params.signal },
  );

  // Success
  return data;
}
