import * as bairdResearchService from "#app/_api/baird-research-service";
import * as financialPlansService from "#app/_api/financial-plans-service";
import * as heldawayService from "#app/_api/heldaway-service";
import * as performanceService from "#app/_api/performance-reports-service";
import * as trustService from "#app/_api/external-trust-service";
import * as userProfileService from "#app/_api/user-profile-service";
import { useQuery } from "@tanstack/react-query";

export interface RequiredData {
  hasBairdResearch: boolean;
  canUseFinancialPlanning: boolean;
  hasVisiblePlans: boolean|undefined;
  hasApl: boolean;
  canUseTrust: boolean;
  heldawayUserEligibility: boolean
}

interface RequiredDataQueryResult {
  isPendingRequiredData: boolean;
  requiredData: RequiredData;
}

export function useRequiredData() {
  const resultAccountGroups = useQuery({
    ...userProfileService.useAccountGroupsQueryOptions(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const resultFinancialPlanStatus = useQuery({
    ...financialPlansService.useFinancialPlansStatusQueryOptions(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const resultHasApl = useQuery({
    ...performanceService.useHasAplQueryOptions(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const resultAccountNickName = useQuery({
    ...userProfileService.useAccountNickNameQueryOptions(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const resultBairdResearchAccess = useQuery({
    ...bairdResearchService.useQueryOptionsResearchHeadlinesHasAccess(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const resultEligibilityMenu = useQuery({
    ...trustService.useTrustEligibilityMenuQueryOptions(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const userEligibility = useQuery({
    ...heldawayService.useHeldawayUserEligibilityQueryOptions(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
  
  return {
    isPendingRequiredData: [
      resultAccountGroups,
      resultFinancialPlanStatus,
      resultHasApl,
      resultAccountNickName,
      resultBairdResearchAccess,
      resultEligibilityMenu,
      userEligibility
    ].some((result) => result.isPending),

    requiredData: {
      hasBairdResearch: Boolean(resultBairdResearchAccess.data),

      canUseFinancialPlanning: Boolean(
        resultFinancialPlanStatus?.data?.canUseFinancialPlanning,
      ),

      hasVisiblePlans: resultFinancialPlanStatus?.data?.hasVisiblePlans,

      hasApl: Boolean(resultHasApl?.data),

      canUseTrust: resultEligibilityMenu.data?.trust === "Allow",
      heldawayUserEligibility: userEligibility.data ?? false
    },
  } satisfies RequiredDataQueryResult;
}
