import { useMemo, useSyncExternalStore } from "react";
import { type Jwt, getCurrentJwt, subscribe } from "./jwt";
import { useAccountNicknameLookup } from "./useAccountNicknameLookup";
import { TimeSpan } from "#app/lib/TimeSpan";

export function useJwt() {
  const jwt = useSyncExternalStore<Jwt>(subscribe, getCurrentJwt);
  return jwt;
}

export function useJwtAccounts() {
  const jwt = useJwt();
  return useMemo(() => jwt.accounts, [jwt]);
}

export function useJwtFirstAccount() {
  const accounts = useJwtAccounts();
  return useMemo(() => accounts[0], [accounts]);
}

type AccountWithName = {
  number: string;
  name: string;
};
const sortNames = (
  { name }: AccountWithName,
  { name: otherName }: AccountWithName,
) => {
  if (name === otherName) {
    return 0;
  }
  return name > otherName ? 1 : -1;
};

export function useJwtAccountsSorted() {
  const nicknameLookup = useAccountNicknameLookup();
  const accounts = useJwtAccounts();
  const namedAccounts = accounts.map((a) => ({
    number: a,
    name: nicknameLookup[a] || "",
  }));
  const sortedAccounts = namedAccounts.sort(sortNames);
  const accountNumbers = sortedAccounts.map(({ number }) => number);
  return accountNumbers;
}

export type SortedIndexLookup = {
  [Account: string]: number;
};

export function useJwtAccountsSortedIndexLookup() {
  const sortedAccountNumbers = useJwtAccountsSorted();
  const result = sortedAccountNumbers.reduce((acc: SortedIndexLookup, cur, i)=> ({...acc, [cur]: i}),{})
  return result;
}

export function applyJwtForDigitalOnboarding() {
  const cookieValue = getCurrentJwt().jwtAsString;
  const expirationDate = TimeSpan.fromMinutes(6).totalSeconds;

  document.cookie=`BOLAccessToken=${cookieValue};secure;max-age=${expirationDate};domain=.rwbaird.com;path=/;`
}

export function jwtAccountEncrypted(accountNumber: string){
  const accounts = getCurrentJwt().jwtAccounts;
  return accounts.find(acc => acc.num === accountNumber)!.e;
}
