import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type AutocompleteResponse } from "./model-types";

export type GetAutocompleteParams = {
  search: string;
  numberOfResults?: number;
};

export type GetAutocompleteData = AutocompleteResponse;

export async function getAutocomplete(
  params: GetAutocompleteParams & Pick<Options, "signal">,
): Promise<GetAutocompleteData> {
  // Parse incoming
  const searchParams = parseParams(params);

  // Call endpoint
  const data = await getJson<GetAutocompleteData>(
    `${GATEWAY_PATHS.externalMarketResearchService}/Autocomplete${searchParams}`,
    { signal: params?.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetAutocompleteParams) {
  // Destructure params
  const { numberOfResults, search } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams({ search });
  if (numberOfResults && numberOfResults > 0) {
    urlSearchParams.set("numberOfResults", String(numberOfResults));
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
