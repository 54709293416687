import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type HistoricalValues } from "./model-types";

export type GetHistoricalValuesParams = {
  fromDate?: string | undefined;
  toDate?: string | undefined;
  annualizedOnly?: boolean | undefined;
  acctIndex?: [number, ...number[]] | undefined;
};

export type GetHistoricalValuesData = HistoricalValues;

export async function getHistoricalValues(
  params: GetHistoricalValuesParams & Pick<Options, "signal">,
): Promise<GetHistoricalValuesData> {
  // Parse incoming
  const searchParams = params ? parseParams(params) : "";

  // Call endpoint
  const data = await getJson<GetHistoricalValuesData>(
    `${GATEWAY_PATHS.pwmPortfolioService}/HistoricalValues${searchParams}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetHistoricalValuesParams) {
  // Destructure params
  const { fromDate, toDate, annualizedOnly, acctIndex } = params;

  // Build urlSearchParams
  const urlSearchParams = new URLSearchParams();
  if (fromDate) {
    urlSearchParams.set("fromDate", fromDate);
  }
  if (toDate) {
    urlSearchParams.set("toDate", toDate);
  }
  if (annualizedOnly !== undefined) {
    urlSearchParams.set("annualizedOnly", String(annualizedOnly));
  }
  if (acctIndex) {
    urlSearchParams.set("acctIndex", acctIndex.join(","));
  }

  // Create searchParams string
  const searchParams =
    urlSearchParams.size > 0 ? `?${urlSearchParams.toString()}` : "";

  return searchParams;
}
